import loadPolyfills from 'utils/load-polyfills';

loadPolyfills(async () => {
  // TODO: enable PWA bundle entry
  const isPWA = false && window.innerWidth <= 1024;
  const prefix = isPWA ? 'pwa' : 'app';
  const targetCss = document.createElement('link');

  targetCss.href = `/${prefix}-${env.md5}.css`;
  targetCss.rel = 'stylesheet';
  targetCss.type = 'text/css';
  targetCss.onload = function() {
    const targetScript = document.createElement('script');
    targetScript.src = `/${prefix}-${env.md5}${isPWA ? '.pwa' : ''}.js`;
    document.head.appendChild(targetScript);
  };
  document.head.appendChild(targetCss);
});
