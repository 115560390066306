export default (cb: () => void) => {
  const ua = navigator.userAgent;
  const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1 || ua.indexOf('Edge') > -1;

  if (typeof Promise === 'undefined' || isIE) {
    const polyfill = document.createElement('script');
    polyfill.onload = cb;
    polyfill.src = `/polyfills-${env.md5}.js`;
    document.head.appendChild(polyfill);
  } else {
    cb();
  }
};
